<template>
  <footer class="flex flex-fd-c flex-jc-c">
    <p>
      Website desenvolvido por
      <u
        ><a href="mailto:nunopereirasousa00@gmail.com">Nuno Pereira Sousa</a></u
      >
    </p>
    <small>Privacy Policy & © Copy Right 2021</small>
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
